import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import getSupplierInfoFromDB from './Utilities/getSupplierInfoFromDB';
import getAssessmentsInfoFromDB from './Utilities/getAssessmentsInfoFromDB';
import TopBar from '../../NavBars/TopBar';
import Box from '@mui/material/Box';
import Header from '../../Components/Headers/Header';
import SupplierInfoEditableField from './Subcomponents/SupplerInfoEditableField';
import AssessmentField from './Subcomponents/AssessmentField';
import { supabase } from '../../Utilities/SupabaseClient';
import styles from './SupplierPage.module.css';
import { NUMBER_OF_ASSESSMENTS } from '../../Utilities/Constants';
import SidebarPanel from '../../NavBars/SideBarPanel';

import DeleteSupplier from './Subcomponents/DeleteSupplier';

// This page shows information for a specific supplier and allows the
// user to edit that information.
const SupplierPage = () => {
	const params = useParams();
	const initialState = {
		company_name: '',
		contact_first_name: '',
		contact_last_name: '',
		email: '',
		phone: '',
	};
	const keys = Object.keys(initialState);
	const [supplierInfo, setSupplierInfo] = useState(initialState);
	const [assessments, setAssessments] = useState([]);
	const [session, setSession] = useState(null);

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
		});

		supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
		});
	}, []);

	useEffect(() => {
		// Pulling in supplier info from Postgres DB.
		async function getSupplierInfo() {
			const incomingSupplierData = await getSupplierInfoFromDB(
				params.supplierid
			);
			const newSupplierInfo = {
				company_name: incomingSupplierData['company_name'],
				contact_first_name: incomingSupplierData['contact_first_name'],
				contact_last_name: incomingSupplierData['contact_last_name'],
				email: incomingSupplierData['email'],
				phone: incomingSupplierData['phone'],
			};
			setSupplierInfo(newSupplierInfo);
			// setAssessments(incomingSupplierData['assessments']);
		}
		getSupplierInfo();
	}, [params.supplierid]);

	useEffect(() => {
		// Pulling in assessments sent info from DB.
		async function getAssessmentInfo() {
			const incomingAssessmentsInfo = await getAssessmentsInfoFromDB(
				params.supplierid
			);
			const assessmentsSent = incomingAssessmentsInfo[0];
			const assessmentsWithResponse = incomingAssessmentsInfo[1];
      
			const assessmentsUUID = incomingAssessmentsInfo[2];
			let assessmentsArr = [];
			// This loop creates an array of all assessments. This will later
			// be used the create an array of AssessmentField objects to render.
			for (let i = 0; i < NUMBER_OF_ASSESSMENTS; i++) {
				const assessmentNumber = 10001 + i;
				let assessmentStatus = 'not_sent';
				let assessmentUUID = null;
				if (assessmentsSent.includes(assessmentNumber))
					assessmentStatus = 'sent';

				const index = assessmentsSent.indexOf(assessmentNumber);
				assessmentUUID = assessmentsUUID[index];

				if (assessmentsWithResponse.includes(assessmentNumber))
					assessmentStatus = 'response_received';
				assessmentsArr.push({
					assessment_number: assessmentNumber,
					status: assessmentStatus,
					uuid: assessmentUUID,
				});
			}
			setAssessments(assessmentsArr);
		}
		getAssessmentInfo();
	}, [params.supplierid]);

	const initialStateStr = localStorage.getItem('checked_state');
	let stateToUse = false;
	if (initialStateStr === 'true') stateToUse = true;
	else if (initialStateStr === 'false') stateToUse = false;

	const editableFields = keys.map((key, index) => {
		return (
			<SupplierInfoEditableField
				key={index.toString()}
				fieldName={key}
				fieldValue={supplierInfo[key]}
				setterFn={setSupplierInfo}
				currentSupplierInfo={supplierInfo}
				supplierid={params.supplierid}
			/>
		);
	});

	const assessmentFields = assessments.map((item, index) => {
    console.log(item['assessment_number'], "/" , item['status']);
    console.log(stateToUse);
    
		return (
			<AssessmentField
				key={index.toString()}
				assessmentNumber={item['assessment_number']}
				assessmentStatus={item['status']}
				supplierName={supplierInfo['company_name']}
				toEmail={supplierInfo['email']}
				supplierid={params.supplierid}
				session={session}
			/>
		);
	});

	return (
		<>
			<SidebarPanel />
			<main className={styles.supplierpagemain}>
				<nav>
					<TopBar />
				</nav>
				<Box m='20px'>
					<Header
						title='SUPPLIER ASSESSMENTS & INFORMATION'
						subtitle={supplierInfo['company_name']}
					/>
				</Box>
				<div className={styles.supplierpageassessmentstitle}>Assessments</div>
				{session && (
					<div className={styles.supplierpageassessmentswrapper}>
						{assessmentFields}
					</div>
				)}
				<div className={styles.supplierpagesititle}>Supplier Information</div>
				<div className={styles.supplierpageeditableswrapper}>
					{editableFields}
				</div>

				<div className={styles.supplierpagesititle}>Delete Supplier</div>
				<DeleteSupplier
					companyName={supplierInfo['company_name']}
					UUID={params.supplierid}
				/>
			</main>
		</>
	);
};

export default SupplierPage;
