import { supabase } from '../../../../Utilities/SupabaseClient';

export const insertIntoDB = async (values, assessmentid) => {
	try {
		const { error } = await supabase
			// Selects the proper table
			.from('supplier_assessment_10008')
			// Inserts values from form into table
			.insert(
				{
					assessment_uuid: assessmentid,
					company_name: values.companyName ? values.companyName : null,
					remote_hrs_worked: values.remoteHrsWorked
						? values.remoteHrsWorked
						: null,
				},
				{ returning: 'minimal' }
			);
		if (error) console.log(error);
		if (error) throw error;
	} catch (err) {
		console.log(err);
		throw err;
	}
};
