import { supabase } from '../../../Utilities/SupabaseClient';

// This function gets the suppliers from the DB. It should only get
// suppliers associated with the logged in user, given the RLS setup.
export const getSuppliersFromDB = async () => {
	try {
		const session = await supabase.auth.getSession();
		const userid = session.data.session.user.id;
		const { data, err } = await supabase
			.from('suppliers')
			.select('*')
			.eq('user_added_supplier_id', userid)
			.eq('isRemoved', false);
		// Error block here in case the catch block does not
		// recognize the error. Not sure how Supabase handles the error
		// message so including this here. May not be necessary.
		if (err) throw err;
		const cleanedData = cleanData(data);
		return cleanedData;
	} catch (e) {
		console.log(e);
		throw e;
	}
};

// This function takes the data from the DB and returns the data needed by
// the MUI grid table used to display the suppliers.
const cleanData = (data) => {
	const cleanData = [];
	data.forEach((item) => {
		// let supplierText = 'No assessment sent';
		// Will need to add if then statements to deal with other supplier status messages
		const supplierPage = '/supplier/' + item['uuid'];
		const itemToAdd = {
			id: item['id'],
			company_name: item['company_name'],
			email: item['email'],
			phone: item['phone'],
			contact_first_name: item['contact_first_name'],
			contact_last_name: item['contact_last_name'],
			// supplier_status: supplierText,
			supplier_page: supplierPage,
		};
		cleanData.push(itemToAdd);
	});
	return cleanData;
};
