import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import styles from './DeleteSupplier.module.css';
import { supabase } from '../../../Utilities/SupabaseClient';

const DeleteSupplier = ({ companyName, UUID }) => {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	const updateDeleteInDB = async () => {
		try {
			await supabase
				.from('suppliers')
				.update({ isRemoved: true })
				.eq('uuid', UUID);
		} catch (e) {
			throw e;
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleDeleteClick = () => {
		updateDeleteInDB();
		setOpen(false);
		navigate('/supplierslist');
	};

	return (
		<>
			<div className={styles.delete_supplier_section}>
				<div className={styles.supplier_delete_main_text}>
					Delete This Supplier <br />{' '}
					<span className={styles.supplier_delete_sub_text}>
						Deleting will remove the Supplier from your list.
					</span>
				</div>
				<div className={styles.delete_supplier_button}>
					<Button
						onClick={() => {
							handleClickOpen();
						}}
						variant='contained'
						color='error'
					>
						Delete
					</Button>
				</div>
			</div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>
					{`Remove ${companyName} from list?`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Removing the supplier does not permanately delete the user, instead
						it removes the supplier from your view. In order to re-instate the
						supplier and view thier information you must contact EarthScope.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						onClick={handleDeleteClick}
						variant='contained'
						color='error'
						autoFocus
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DeleteSupplier;
