import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';

import AssessmentHeader from '../../../Components/Headers/AssessmentHeader';
import OneLineInput from '../../../Components/OneLineInput/OneLineInput';

import { insertIntoDB } from './Utilities/insertIntoDB';
import setAssessmentResponseField from '../SupplierInitialAssessment/Utilities/setAssessmentResponseField';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import styles from '../SupplierInitialAssessment.module.css';
import GoBackLink from '../Subcomponents/GoBackLink';

// Begin assessment 10007 component.
const SupplierAssessment10008 = ({ assessmentid, disableBtn }) => {
	// Gets assessment title
	const title = getAssessmentTitle(10008);
	const navigate = useNavigate();

	// Initial form values set to empty strings
	const initialValues = {
		companyName: undefined,
		remoteHrsWorked: undefined,
	};

	// Validates inputs for required fields
	const validateSchema = yup.object().shape({
		companyName: yup.string().required('Required'),
		remoteHrsWorked: yup
			.number()
			.required('Enter a number')
			.min(0, 'Must be zero or positive'),
	});

	// Inserts data into the datbase when submit button is clicked
	const handleFormSubmit = (values, { resetForm }) => {
		try {
			if (!disableBtn) {
				insertIntoDB(values, assessmentid);
				setAssessmentResponseField(assessmentid);
			}
			resetForm(initialValues);
			navigate('/confirmation');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<main className={styles.initialAssessmentMain}>
				<Box mt='20px' sx={{ maxWidth: 'md' }}>
					<GoBackLink />
					<AssessmentHeader title={title} />
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
					>
						<div>
							<Formik
								onSubmit={handleFormSubmit}
								initialValues={initialValues}
								validationSchema={validateSchema}
							>
								{({
									values,
									errors,
									touched,
									handleBlur,
									handleChange,
									handleSubmit,
								}) => (
									<form onSubmit={handleSubmit}>
										<Box
											display='grid'
											gap='0px'
											gridTemplateColumns='repeat(1, minmax(0, 1fr))'
										>
											<div className={styles.supplierassessmentdirections}>
												This assessment allows you to submit for the remote
												hours worked, to include Independent Contractors.
											</div>
											<Box />

                      <Box height={30} />
											<div>
												<OneLineInput
													inputLabel='Your Company Name'
													type='text'
													label='Your Company Name'
													name='companyName'
													onBlur={handleBlur}
													onChange={handleChange}
													error={!!touched.companyName && !!errors.companyName}
													helperText={touched.companyName && errors.companyName}
												/>
											</div>
											<Box height={30} />
											<div>
												<OneLineInput
													inputLabel="How many total hours of remote work (or work-from-home) do your company’s employees do?"
													type='number'
													label='Remote Hours Worked'
													name='remoteHrsWorked'
													onBlur={handleBlur}
													onChange={handleChange}
													error={
														!!touched.remoteHrsWorked &&
														!!errors.remoteHrsWorked
													}
													helperText={
														touched.remoteHrsWorked && errors.remoteHrsWorked
													}
												/>
											</div>
										</Box>
										<Box display='flex' sx={{ m: '25px 0px 50px 0px' }}>
											<Button
												type='submit'
												color='secondary'
												variant='contained'
											>
												Submit Form
											</Button>
										</Box>
									</form>
								)}
							</Formik>
						</div>
					</Grid>
				</Box>
			</main>
		</>
	);
};

export default SupplierAssessment10008;
